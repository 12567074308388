/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox, Select } from 'antd';
import classNames from 'classnames';

import Field from '../../../../UI/field';
import { createDepthBotInputs } from '../../../../../helpers/createProviderBotInputs';

import style from '../create-provider-bot-page/create-provider-bot-view.module.scss';
import '../create-provider-bot-page/create-provider-bot-view.scss';
import GreenRedButtonBlock from '../../../../UI/green-red-button-block';

const CreateDepthBotView = ({
    createNewBot: {
        priceSource,
        id,
        pairSubstituteEnabled,
        tradingOnTargetExchangeEnabled,
        sourceVolumeEnabled,
        //
        substitutePairPriceCoefficient,
        // substitutePair,
        substitutePairName,
        // directPair,
        directPairName,
        // pairName,
        substitutePairAutoPriceEnabled,
        directPairCourse,
        substitutePairAutoPriceCurrencySource,
        substitutePairAutoPriceCurrencyTarget,
    },
    errors: {
        sourceError,
        pairNameError,
        substitutePairPriceCoefficientError,
        directPairError,
        substitutePairAutoPriceCurrencySourceError,
        substitutePairAutoPriceCurrencyTargetError,
        substitutePairNameError,

    },
    t,
    allCoins,
    errors,
    createNewBot,
    inputOnchange,
    selectOnChange,
    cancelCreateBot,
    submitcreateNewBot,
    createDepthBotLoading,
    allSources,
    switchTradingOnTargetExchangeEnabled,
    commonPairs,
    targetPairs,
    switchSourceVolumeEnabled,
    //
    pairSubstitutionToggle,
    currenciesAll,
    switchCustomPrice,
    switchDirectBinanceRate,
}) => {
    const { Option } = Select;
    console.log('allCoins', allCoins);
    const isPairSubstituteEnabled = pairSubstituteEnabled ? targetPairs : commonPairs;

    return (
        <form className={style.createNewBot}>
            <div className={style.createNewBot__titleWrapper}>
                <p className={style.createNewBot__title}>
                    {t('aside.liquidityProvider')} / {t('aside.createNewBot')}
                </p>
                <Checkbox
                    className="pairSubstitution"
                    checked={pairSubstituteEnabled}
                    onChange={pairSubstitutionToggle}
                >
                    {t('createBot.pairSubstitution')}
                </Checkbox>
            </div>
            <div className={style.createNewBot__wrapper}>
                <div className={style.createNewBot__wrapper_leftSide}>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('createBot.setSourceLiquidity')}</p>
                        <Select
                            showSearch
                            value={priceSource}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {allSources.map(item => (
                                <Option key={item} name="priceSource" value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                        {sourceError ? (
                            <div
                                className={style.createNewBot__wrapperInput_invalid}
                            >{sourceError}
                            </div>
                        ) : null}
                    </div>

                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('generals.pair')}</p>
                        <Select
                            showSearch
                            value={id || undefined}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {isPairSubstituteEnabled.map(items => {
                                const {
                                    left,
                                    right,
                                } = items;
                                return (
                                    <Option
                                        key={`${left}-${right}`}
                                        name="id"
                                        value={`${left}-${right}`}
                                    >
                                        {`${left}-${right}`}
                                    </Option>
                                );
                            })}
                        </Select>
                        {pairNameError ? (
                            <div
                                className={style.createNewBot__wrapperInput_invalid}
                            >{pairNameError}
                            </div>
                        ) : null}
                    </div>

                    {pairSubstituteEnabled ? (
                        <div className={style.createNewBot__wrapperInput}>
                            <p className={style.createNewBot__wrapper_title}>{t('createBot.sourcePair')}</p>
                            <Select
                                showSearch
                                value={substitutePairName || undefined}
                                className="antDSelect"
                                placeholder={t('generals.choose')}
                                onChange={selectOnChange}
                            >
                                {allCoins.map(items => {
                                    const { left, right } = items;
                                    return (
                                        <Option
                                            key={`${left}-${right}`}
                                            name="substitutePairName"
                                            value={`${left}-${right}`}
                                        >
                                            {`${left}-${right}`}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {substitutePairNameError ? (
                                <div className={style.createNewBot__wrapperInput_invalid}>
                                    {substitutePairNameError}
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {createDepthBotInputs.map(item => {
                        const {
                            value,
                            label,
                            error,
                            type,
                        } = item;

                        if (value === 'sourceVolumePercent' && !sourceVolumeEnabled) return null;

                        return (
                            <div key={value} className={style.createNewBot__wrapperInput}>
                                <Field
                                    id={value}
                                    type={type}
                                    disabled={value === 'sourceVolumePercent' && !sourceVolumeEnabled}
                                    placeholder={t('generals.typeHere')}
                                    name={value}
                                    value={createNewBot[value]}
                                    error={errors[error]}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t(`${label}`)}
                                    labelStyle={style.createNewBot__wrapper_label}
                                />
                            </div>
                        );
                    })}
                    {pairSubstituteEnabled ? (
                        <Fragment>
                            {!substitutePairAutoPriceEnabled ? (
                                <div className={style.createNewBot__wrapperInput}>
                                    <Field
                                        id="substitutePairPriceCoefficient"
                                        type="number"
                                        placeholder={t('generals.typeHere')}
                                        name="substitutePairPriceCoefficient"
                                        value={substitutePairPriceCoefficient}
                                        error={substitutePairPriceCoefficientError}
                                        onChange={inputOnchange}
                                        inputStyle={style.createNewBot__wrapper_input}
                                        labelText={t('createBot.substitutePairPriceCoefficient')}
                                        labelStyle={style.createNewBot__wrapper_label}
                                    />
                                </div>
                            ) : (
                                <Fragment>
                                    {directPairCourse ? (
                                        <div className={style.createNewBot__wrapperInput}>
                                            <p className={style.createNewBot__wrapper_title}>
                                                {t('createBot.directRate')}
                                            </p>
                                            <Select
                                                showSearch
                                                value={directPairName || undefined}
                                                className="antDSelect"
                                                placeholder={t('generals.choose')}
                                                onChange={selectOnChange}
                                            >
                                                {allCoins.map(items => {
                                                    const { left, right } = items;
                                                    return (
                                                        <Option
                                                            key={`${left}-${right}`}
                                                            name="directPairName"
                                                            value={`${left}-${right}`}
                                                        >
                                                            {`${left}-${right}`}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {directPairError ? (
                                                <div className={style.createNewBot__wrapperInput_invalid}>
                                                    {directPairError}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <Fragment>
                                            <div className={style.createNewBot__wrapperInput}>
                                                <p className={style.createNewBot__wrapper_title}>
                                                    {t('createBot.substitutePairAutoPriceCurrencySource')}
                                                </p>
                                                <Select
                                                    showSearch
                                                    value={substitutePairAutoPriceCurrencySource || undefined}
                                                    className="antDSelect"
                                                    placeholder={t('generals.choose')}
                                                    onChange={selectOnChange}
                                                >
                                                    {currenciesAll.map(items => (
                                                        <Option
                                                            key={items}
                                                            name="substitutePairAutoPriceCurrencySource"
                                                            value={items}
                                                        >
                                                            {items}
                                                        </Option>
                                                    ))}
                                                </Select>
                                                {substitutePairAutoPriceCurrencySourceError ? (
                                                    <div className={style.createNewBot__wrapperInput_invalid}>
                                                        {substitutePairAutoPriceCurrencySourceError}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className={style.createNewBot__wrapperInput}>
                                                <p className={style.createNewBot__wrapper_title}>
                                                    {t('createBot.substitutePairAutoPriceCurrencyTarget')}
                                                </p>
                                                <Select
                                                    showSearch
                                                    value={substitutePairAutoPriceCurrencyTarget || undefined}
                                                    className="antDSelect"
                                                    placeholder={t('generals.choose')}
                                                    onChange={selectOnChange}
                                                >
                                                    {currenciesAll.map(items => (
                                                        <Option
                                                            key={items}
                                                            name="substitutePairAutoPriceCurrencyTarget"
                                                            value={items}
                                                        >
                                                            {items}
                                                        </Option>
                                                    ))}
                                                </Select>
                                                {substitutePairAutoPriceCurrencyTargetError ? (
                                                    <div className={style.createNewBot__wrapperInput_invalid}>
                                                        {substitutePairAutoPriceCurrencyTargetError}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <div
                                className={classNames(
                                    style.createNewBot__wrapperInput,
                                    style.createNewBot__wrapperInputLabel,
                                )}
                            >
                                <Checkbox checked={substitutePairAutoPriceEnabled} onChange={switchCustomPrice}>
                                    {t('createBot.autoPrice')}
                                </Checkbox>
                            </div>
                            {substitutePairAutoPriceEnabled ? (
                                <div
                                    className={classNames(
                                        style.createNewBot__wrapperInput,
                                        style.createNewBot__wrapperInputLabel,
                                    )}
                                >
                                    <Checkbox checked={directPairCourse} onChange={switchDirectBinanceRate}>
                                        {t('createBot.directBinanceRate')}
                                    </Checkbox>
                                </div>
                            ) : null}
                        </Fragment>
                    ) : null}
                    <div
                        className={classNames(style.createNewBot__wrapperInput, style.createNewBot__wrapperInputLabel)}
                    >
                        <Checkbox
                            className={style.antCheckboxSourceVolume}
                            checked={tradingOnTargetExchangeEnabled}
                            onChange={switchTradingOnTargetExchangeEnabled}
                        >
                            {t('createBot.tradingOnTargetExchangeEnabled')}
                        </Checkbox>
                    </div>
                    <div
                        className={classNames(style.createNewBot__wrapperInput, style.createNewBot__wrapperInputLabel)}
                    >
                        <Checkbox
                            className={style.antCheckboxSourceVolume}
                            checked={sourceVolumeEnabled}
                            onChange={switchSourceVolumeEnabled}
                        >
                            {t('createBot.sourceVolumeEnabled')}
                        </Checkbox>
                    </div>
                </div>
            </div>
            <GreenRedButtonBlock
                greenRedButtonWrapper={style.greenRedButtonWrapper}
                clickGreenBtn={submitcreateNewBot}
                clickRedBtn={cancelCreateBot}
                greenBtnText={t('generals.createNew')}
                redBtnText={t('generals.cancel')}
                disabled={createDepthBotLoading}
            />
        </form>
    );
};

CreateDepthBotView.defaultProps = {
    t: () => {
    },
    inputOnchange: () => {
    },
    selectOnChange: () => {
    },
    cancelCreateBot: () => {
    },
    switchSourceVolumeEnabled: () => {
    },
    submitcreateNewBot: () => {
    },
    switchTradingOnTargetExchangeEnabled: () => {
    },
    pairSubstitutionToggle: () => {},
    errors: {},
    allSources: [],
    commonPairs: [],
    targetPairs: [],
    createNewBot: {},
    createDepthBotLoading: false,
    allCoins: [],
    currenciesAll: [],
    switchCustomPrice: () => {},
    switchDirectBinanceRate: () => {},
    substitutePairName: '',
};

CreateDepthBotView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    selectOnChange: PropTypes.func,
    cancelCreateBot: PropTypes.func,
    switchSourceVolumeEnabled: PropTypes.func,
    submitcreateNewBot: PropTypes.func,
    switchTradingOnTargetExchangeEnabled: PropTypes.func,
    errors: PropTypes.object,
    allSources: PropTypes.arrayOf(PropTypes.object),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    createNewBot: PropTypes.object,
    createDepthBotLoading: PropTypes.bool,
    pairSubstitutionToggle: PropTypes.func,
    allCoins: PropTypes.arrayOf(PropTypes.object),
    currenciesAll: PropTypes.any,
    switchCustomPrice: PropTypes.func,
    switchDirectBinanceRate: PropTypes.func,
    substitutePairName: PropTypes.string,
};

export default withTranslation()(CreateDepthBotView);
