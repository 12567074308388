import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Select, Checkbox } from 'antd';
import classNames from 'classnames';
import ManualOrdersContainer from './manual-orders';
import toFixedBigValue from '../../../../../helpers/big-number';
import Button from '../../../../UI/button';
import Field from '../../../../UI/field';
import spinner from '../../../../spinner/spinner.svg';
import style from './edit-order.module.scss';

const EditOrderView = ({
    t,
    targetPairs,
    inputOnchange,
    toggleCheckbox,
    update,
    frequencyMinError,
    frequencyMaxError,
    volumeLimitError,
    userIdError,
    buySellCoefError,
    ordersQuantityMinError,
    ordersQuantityMaxError,
    minOrderVolumeError,
    maxOrderVolumeError,
    buyValueMinError,
    buyValueMaxError,
    loading,
    scheduledOrdersData: {
        buySellCoef,
        frequencyMax,
        frequencyMin,
        id,
        ordersQuantityMax,
        ordersQuantityMin,
        minOrderVolume,
        maxOrderVolume,
        userId,
        buyValueMin,
        buyValueMax,
        volumeLimit,
        pairName,
        trackSellToBuyOrders,
        fakeVolumeControl,
        cancelManualOrdersOnDelete,
    },
}) => {
    const { Option } = Select;
    let dailyVolume = '';
    let dailyVolumeMin = null;
    let dailyVolumeMax = null;
    if (frequencyMin && frequencyMax && buyValueMin && buyValueMax) {
        dailyVolumeMin = ((60 * 60 * 24) / frequencyMax) * buyValueMin;
        dailyVolumeMax = ((60 * 60 * 24) / frequencyMin) * buyValueMax;
    } else {
        dailyVolumeMin = null;
        dailyVolumeMax = null;
    }

    if (dailyVolumeMin && dailyVolumeMax) {
        dailyVolume = `${toFixedBigValue(dailyVolumeMin, 5)} - ${toFixedBigValue(dailyVolumeMax, 5)}`;
    } else {
        dailyVolume = '';
    }

    return (
        <div className={style.orders}>
            {loading ? (
                <div className={style.spinner}>
                    <img src={spinner} alt="spinner" />
                </div>
            ) : null}
            <div className={style.orders__titleWrapper}>
                <p className={style.orders__title}>
                    {t('generals.edit')} - {id} - {t('generals.scheduledOrders')}
                </p>
                <Checkbox
                    className="trackSellToBuyOrders"
                    checked={fakeVolumeControl}
                    onChange={event => toggleCheckbox(event, 'fakeVolumeControl')}
                >
                    {t('generals.fakeVolumeControl')}
                </Checkbox>
            </div>
            <div className={style.orders__row}>
                <div className={style.orders__wrapperInput}>
                    <p className={style.orders__wrapper_title}>{t('generals.pair')}</p>
                    <Select
                        showSearch
                        value={pairName || undefined}
                        className="antDSelect disabledField"
                        placeholder={t('generals.choose')}
                    >
                        {targetPairs.map(items => {
                            const { left, right } = items;
                            return (
                                <Option key={`${left}-${right}`} name="pairName" value={`${left}-${right}`}>
                                    {`${left}-${right}`}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="userId"
                        type="text"
                        placeholder={t('generals.typeHere')}
                        name="userId"
                        value={userId}
                        error={userIdError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.userId')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="buySellCoef"
                        type="text"
                        placeholder={t('generals.typeHere')}
                        name="buySellCoef"
                        value={buySellCoef}
                        error={buySellCoefError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.buySellCoef')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="volumeLimit"
                        type="number"
                        placeholder={t('generals.amount')}
                        name="volumeLimit"
                        value={volumeLimit}
                        error={volumeLimitError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.tradeVolumeLimit')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
            </div>
            <div className={style.orders__row}>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="buyValueMin"
                        type="number"
                        placeholder={t('generals.min')}
                        name="buyValueMin"
                        value={buyValueMin}
                        error={buyValueMinError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.valueMin')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="buyValueMax"
                        type="number"
                        placeholder={t('generals.max')}
                        name="buyValueMax"
                        value={buyValueMax}
                        error={buyValueMaxError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.valueMax')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="minOrderVolume"
                        type="number"
                        placeholder={t('generals.min')}
                        name="minOrderVolume"
                        value={minOrderVolume}
                        error={minOrderVolumeError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.minOrderVolume')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="maxOrderVolume"
                        type="number"
                        placeholder={t('generals.max')}
                        name="maxOrderVolume"
                        value={maxOrderVolume}
                        error={maxOrderVolumeError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.maxOrderVolume')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
            </div>
            <div className={style.orders__row}>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="ordersQuantityMin"
                        type="number"
                        placeholder={t('generals.amount')}
                        name="ordersQuantityMin"
                        value={ordersQuantityMin}
                        error={ordersQuantityMinError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.ordersPerDealsMin')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="ordersQuantityMax"
                        type="number"
                        placeholder={t('generals.amount')}
                        name="ordersQuantityMax"
                        value={ordersQuantityMax}
                        error={ordersQuantityMaxError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.ordersPerDealsMax')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="frequencyMin"
                        type="number"
                        placeholder={t('generals.min')}
                        name="frequencyMin"
                        value={frequencyMin}
                        error={frequencyMinError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.frequencyMin')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="frequencyMax"
                        type="number"
                        placeholder={t('generals.max')}
                        name="frequencyMax"
                        value={frequencyMax}
                        error={frequencyMaxError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.frequencyMax')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
            </div>
            <div className={style.orders__row}>
                <div className={classNames(style.orders__wrapperInput, style.orders__wrapperInput_disabled_left)}>
                    <Field
                        id="dailyVolume"
                        type="text"
                        placeholder={t('generals.dailyVolume')}
                        name="dailyVolume"
                        value={dailyVolume}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.dailyVolume')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <Button id="update" type="button" className={style.orders__updateBtn} onClick={update}>
                    {t('generals.update')}
                </Button>
            </div>
            <div className={style.line} />
            <div className={style.orders__titleWrapper}>
                <p className={style.orders__title}>
                    {t('generals.edit')} - {id} - {t('generals.manualOrders')}
                </p>

                <Checkbox
                    className="trackSellToBuyOrders"
                    checked={trackSellToBuyOrders}
                    onChange={event => toggleCheckbox(event, 'trackSellToBuyOrders')}
                >
                    {t('generals.trackSellToBuyOrders')}
                </Checkbox>
                <Checkbox
                    className="trackSellToBuyOrders"
                    checked={cancelManualOrdersOnDelete}
                    onChange={event => toggleCheckbox(event, 'cancelManualOrdersOnDelete')}
                >
                    {t('generals.cancelManualOrdersOnDelete')}
                </Checkbox>
            </div>
            <ManualOrdersContainer />
        </div>
    );
};

EditOrderView.defaultProps = {
    t: () => {},
    inputOnchange: () => {},
    update: () => {},
    toggleCheckbox: () => {},
    targetPairs: [],
    scheduledOrdersData: {},
    userIdError: '',
    buyValueMinError: '',
    buyValueMaxError: '',
    frequencyMinError: '',
    frequencyMaxError: '',
    volumeLimitError: '',
    buySellCoefError: '',
    ordersQuantityMinError: '',
    ordersQuantityMaxError: '',
    minOrderVolumeError: '',
    maxOrderVolumeError: '',
    loading: true,
};

EditOrderView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    update: PropTypes.func,
    toggleCheckbox: PropTypes.func,
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    scheduledOrdersData: PropTypes.object,
    userIdError: PropTypes.string,
    buyValueMinError: PropTypes.string,
    buyValueMaxError: PropTypes.string,
    frequencyMinError: PropTypes.string,
    frequencyMaxError: PropTypes.string,
    volumeLimitError: PropTypes.string,
    buySellCoefError: PropTypes.string,
    ordersQuantityMinError: PropTypes.string,
    ordersQuantityMaxError: PropTypes.string,
    minOrderVolumeError: PropTypes.string,
    maxOrderVolumeError: PropTypes.string,
    loading: PropTypes.bool,
};

export default withTranslation()(EditOrderView);
