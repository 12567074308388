/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox, Select } from 'antd';
import classNames from 'classnames';

import Field from '../../../../../UI/field';
import { createDepthBotInputs } from '../../../../../../helpers/createProviderBotInputs';

import style from '../../create-provider-bot-page/create-provider-bot-view.module.scss';
import '../../create-provider-bot-page/create-provider-bot-view.scss';
import GreenRedButtonBlock from '../../../../../UI/green-red-button-block';

const ViewDepthBotView = ({
    createNewBot: {
        priceSource,
        id,
        pairSubstituteEnabled,
        tradingOnTargetExchangeEnabled,
        sourceVolumeEnabled,
        //
        substitutePairName, //+
        substitutePairAutoPriceEnabled, //+
        substitutePairPriceCoefficient, //+
        directPairCourse, //+
        directPairName, //+
        substitutePairAutoPriceCurrencySource,
        substitutePairAutoPriceCurrencyTarget,
    },
    errors: {
        sourceError,
        pairNameError,
        //
        substitutePairNameError, //+
        substitutePairPriceCoefficientError, //+
        directPairError, //+
        substitutePairAutoPriceCurrencySourceError, //+
        substitutePairAutoPriceCurrencyTargetError, //+
    },
    t,
    errors,
    createNewBot,
    inputOnchange,
    selectOnChange,
    cancelCreateBot,
    submitcreateNewBot,
    editDepthBotLoading,
    allSources,
    commonPairs,
    targetPairs,
    switchTradingOnTargetExchangeEnabled,
    switchSourceVolumeEnabled,
    //
    pairSubstitutionToggle, //+
    allCoins, //+
    currenciesAll, //+
    switchCustomPrice, //+
    switchDirectBinanceRate, //+
}) => {
    const { Option } = Select;

    const isPairSubstituteEnabled = pairSubstituteEnabled ? targetPairs : commonPairs;

    return (
        <form className={style.createNewBot}>
            <div className={style.createNewBot__titleWrapper}>
                <p className={style.createNewBot__title}>
                    {t('aside.liquidityProvider')} / {t('aside.createNewBot')}
                </p>
                <Checkbox
                    disabled
                    className="pairSubstitution"
                    checked={pairSubstituteEnabled}
                    onChange={pairSubstitutionToggle}
                >
                    {t('createBot.pairSubstitution')}
                </Checkbox>
            </div>
            <div className={style.createNewBot__wrapper}>
                <div className={style.createNewBot__wrapper_leftSide}>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('createBot.setSourceLiquidity')}</p>
                        <Select
                            disabled
                            showSearch
                            value={priceSource}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {allSources && allSources.length
                                ? allSources?.map(item => (
                                    <Option key={item} name="priceSource" value={item}>
                                        {item}
                                    </Option>
                                ))
                                : null}
                        </Select>
                        {sourceError ? (
                            <div
                                className={style.createNewBot__wrapperInput_invalid}
                            >{sourceError}
                            </div>
                        ) : null}
                    </div>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('generals.pair')}</p>
                        <Select
                            disabled
                            showSearch
                            value={id || undefined}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {isPairSubstituteEnabled.map(items => {
                                const {
                                    left,
                                    right,
                                } = items;
                                return (
                                    <Option
                                        key={`${left}-${right}`}
                                        name="id"
                                        value={`${left}-${right}`}
                                    >
                                        {`${left}-${right}`}
                                    </Option>
                                );
                            })}
                        </Select>
                        {pairNameError ? (
                            <div
                                className={style.createNewBot__wrapperInput_invalid}
                            >{pairNameError}
                            </div>
                        ) : null}
                    </div>
                    {pairSubstituteEnabled ? (
                        <div className={style.createNewBot__wrapperInput}>
                            <p className={style.createNewBot__wrapper_title}>{t('createBot.sourcePair')}</p>
                            <Select
                                disabled
                                showSearch
                                value={substitutePairName || undefined}
                                className="antDSelect"
                                placeholder={t('generals.choose')}
                                onChange={selectOnChange}
                            >
                                {allCoins.map(items => {
                                    const {
                                        left,
                                        right,
                                    } = items;
                                    return (
                                        <Option
                                            key={`${left}-${right}`}
                                            name="substitutePairName"
                                            value={`${left}-${right}`}
                                        >
                                            {`${left}-${right}`}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {substitutePairNameError ? (
                                <div className={style.createNewBot__wrapperInput_invalid}>
                                    {substitutePairNameError}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    {createDepthBotInputs.map(item => {
                        const {
                            value,
                            label,
                            error,
                            type,
                        } = item;
                        if (value === 'sourceVolumePercent' && !sourceVolumeEnabled) return null;

                        return (
                            <div key={value} className={style.createNewBot__wrapperInput}>
                                <Field
                                    disabled
                                    id={value}
                                    type={type}
                                    placeholder={t('generals.typeHere')}
                                    name={value}
                                    value={createNewBot[value] ?? undefined}
                                    error={errors[error]}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t(`${label}`)}
                                    labelStyle={style.createNewBot__wrapper_label}
                                    styles={{
                                        opacity: value === 'sourceVolumePercent' && !sourceVolumeEnabled ? 0.5 : 1,
                                    }}
                                />
                            </div>
                        );
                    })}
                    {pairSubstituteEnabled ? (
                        <Fragment>
                            {!substitutePairAutoPriceEnabled ? (
                                <div className={style.createNewBot__wrapperInput}>
                                    <Field
                                        disabled
                                        id="substitutePairPriceCoefficient"
                                        type="number"
                                        placeholder={t('generals.typeHere')}
                                        name="substitutePairPriceCoefficient"
                                        value={substitutePairPriceCoefficient}
                                        error={substitutePairPriceCoefficientError}
                                        onChange={inputOnchange}
                                        inputStyle={style.createNewBot__wrapper_input}
                                        labelText={t('createBot.substitutePairPriceCoefficient')}
                                        labelStyle={style.createNewBot__wrapper_label}
                                    />
                                </div>
                            ) : (
                                <Fragment>
                                    {directPairCourse ? (
                                        <div className={style.createNewBot__wrapperInput}>
                                            <p className={style.createNewBot__wrapper_title}>
                                                {t('createBot.directRate')}
                                            </p>
                                            <Select
                                                disabled
                                                showSearch
                                                value={directPairName || undefined}
                                                className="antDSelect"
                                                placeholder={t('generals.choose')}
                                                onChange={selectOnChange}
                                            >
                                                {allCoins.map(items => {
                                                    const {
                                                        left,
                                                        right,
                                                    } = items;
                                                    return (
                                                        <Option
                                                            key={`${left}-${right}`}
                                                            name="directPairName"
                                                            value={`${left}-${right}`}
                                                        >
                                                            {`${left}-${right}`}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {directPairError ? (
                                                <div
                                                    className={style.createNewBot__wrapperInput_invalid}
                                                >
                                                    {directPairError}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <Fragment>
                                            <div className={style.createNewBot__wrapperInput}>
                                                <p className={style.createNewBot__wrapper_title}>
                                                    {t('createBot.substitutePairAutoPriceCurrencySource')}
                                                </p>
                                                <Select
                                                    disabled
                                                    showSearch
                                                    value={substitutePairAutoPriceCurrencySource || undefined}
                                                    className="antDSelect"
                                                    placeholder={t('generals.choose')}
                                                    onChange={selectOnChange}
                                                >
                                                    {currenciesAll.map(items => (
                                                        <Option
                                                            key={items}
                                                            name="substitutePairAutoPriceCurrencySource"
                                                            value={items}
                                                        >
                                                            {items}
                                                        </Option>
                                                    ))}
                                                </Select>
                                                {substitutePairAutoPriceCurrencySourceError ? (
                                                    <div
                                                        className={style.createNewBot__wrapperInput_invalid}
                                                    >
                                                        {substitutePairAutoPriceCurrencySourceError}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className={style.createNewBot__wrapperInput}>
                                                <p className={style.createNewBot__wrapper_title}>
                                                    {t('createBot.substitutePairAutoPriceCurrencyTarget')}
                                                </p>
                                                <Select
                                                    disabled
                                                    showSearch
                                                    value={substitutePairAutoPriceCurrencyTarget || undefined}
                                                    className="antDSelect"
                                                    placeholder={t('generals.choose')}
                                                    onChange={selectOnChange}
                                                >
                                                    {currenciesAll.map(items => (
                                                        <Option
                                                            key={items}
                                                            name="substitutePairAutoPriceCurrencyTarget"
                                                            value={items}
                                                        >
                                                            {items}
                                                        </Option>
                                                    ))}
                                                </Select>
                                                {substitutePairAutoPriceCurrencyTargetError ? (
                                                    <div
                                                        className={style.createNewBot__wrapperInput_invalid}
                                                    >
                                                        {substitutePairAutoPriceCurrencyTargetError}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <div
                                className={classNames(
                                    style.createNewBot__wrapperInput,
                                    style.createNewBot__wrapperInputLabel,
                                )}
                            >
                                <Checkbox
                                    disabled
                                    checked={substitutePairAutoPriceEnabled}
                                    onChange={switchCustomPrice}
                                >
                                    {t('createBot.autoPrice')}
                                </Checkbox>
                            </div>
                            {substitutePairAutoPriceEnabled ? (
                                <div
                                    className={classNames(
                                        style.createNewBot__wrapperInput,
                                        style.createNewBot__wrapperInputLabel,
                                    )}
                                >
                                    <Checkbox
                                        disabled
                                        checked={directPairCourse}
                                        onChange={switchDirectBinanceRate}
                                    >
                                        {t('createBot.directBinanceRate')}
                                    </Checkbox>
                                </div>
                            ) : null}
                        </Fragment>
                    ) : null}
                    <div
                        className={classNames(style.createNewBot__wrapperInput, style.createNewBot__wrapperInputLabel)}
                    >
                        <Checkbox
                            disabled
                            className={style.antCheckboxSourceVolume}
                            checked={tradingOnTargetExchangeEnabled}
                            onChange={switchTradingOnTargetExchangeEnabled}
                        >
                            {t('createBot.tradingOnTargetExchangeEnabled')}
                        </Checkbox>
                    </div>
                    <div
                        className={classNames(style.createNewBot__wrapperInput, style.createNewBot__wrapperInputLabel)}
                    >
                        <Checkbox
                            disabled
                            className={style.antCheckboxSourceVolume}
                            checked={sourceVolumeEnabled}
                            onChange={switchSourceVolumeEnabled}
                        >
                            {t('createBot.sourceVolumeEnabled')}
                        </Checkbox>
                    </div>
                </div>
            </div>
            <GreenRedButtonBlock
                isView
                greenRedButtonWrapper={style.greenRedButtonWrapper}
                clickGreenBtn={submitcreateNewBot}
                clickRedBtn={cancelCreateBot}
                greenBtnText={t('generals.save')}
                redBtnText={t('generals.cancel')}
                disabled={editDepthBotLoading}
            />
        </form>
    );
};

ViewDepthBotView.defaultProps = {
    t: () => {
    },
    inputOnchange: () => {
    },
    selectOnChange: () => {
    },
    cancelCreateBot: () => {
    },
    submitcreateNewBot: () => {
    },
    switchTradingOnTargetExchangeEnabled: () => {
    },
    switchSourceVolumeEnabled: () => {
    },
    errors: {},
    createNewBot: {},
    allSources: [],
    commonPairs: [],
    targetPairs: [],
    editDepthBotLoading: false,
    pairSubstitutionToggle: () => {
    },
    allCoins: [],
    currenciesAll: [],
    switchCustomPrice: () => {
    },
    switchDirectBinanceRate: () => {
    },
};

ViewDepthBotView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    selectOnChange: PropTypes.func,
    cancelCreateBot: PropTypes.func,
    submitcreateNewBot: PropTypes.func,
    switchTradingOnTargetExchangeEnabled: PropTypes.func,
    switchSourceVolumeEnabled: PropTypes.func,
    errors: PropTypes.object,
    allSources: PropTypes.arrayOf(PropTypes.string),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    createNewBot: PropTypes.object,
    editDepthBotLoading: PropTypes.bool,
    pairSubstitutionToggle: PropTypes.func,
    allCoins: PropTypes.arrayOf(PropTypes.object),
    currenciesAll: PropTypes.arrayOf(PropTypes.string),
    switchCustomPrice: PropTypes.func,
    switchDirectBinanceRate: PropTypes.func,
};

export default withTranslation()(ViewDepthBotView);
