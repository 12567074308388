/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Select, Checkbox } from 'antd';
import classNames from 'classnames';
import toFixedBigValue from '../../../../../helpers/big-number';
import Button from '../../../../UI/button';
import Field from '../../../../UI/field';
import style from './create-new-order.module.scss';

const CreateNewOrderView = ({
    t,
    availableNewPairs,
    inputOnchange,
    selectOnChange,
    pairName,
    pairNameError,
    userId,
    userIdError,
    confirm,
    buySellCoef,
    buySellCoefError,
    buyValueMin,
    buyValueMinError,
    buyValueMax,
    buyValueMaxError,
    ordersQuantityMin,
    ordersQuantityMinError,
    ordersQuantityMax,
    ordersQuantityMaxError,
    frequencyMin,
    frequencyMinError,
    frequencyMax,
    frequencyMaxError,
    volumeLimit,
    volumeLimitError,
    minOrderVolume,
    maxOrderVolume,
    minOrderVolumeError,
    maxOrderVolumeError,
    fakeVolumeControl,
    fakeVolumeControlToggle,
}) => {
    const { Option } = Select;
    let dailyVolume = '';
    let dailyVolumeMin = null;
    let dailyVolumeMax = null;
    if (frequencyMin && frequencyMax && buyValueMin && buyValueMax) {
        dailyVolumeMin = ((60 * 60 * 24) / frequencyMax) * buyValueMin;
        dailyVolumeMax = ((60 * 60 * 24) / frequencyMin) * buyValueMax;
    } else {
        dailyVolumeMin = null;
        dailyVolumeMax = null;
    }

    if (dailyVolumeMin && dailyVolumeMax) {
        dailyVolume = `${toFixedBigValue(dailyVolumeMin, 5)} - ${toFixedBigValue(dailyVolumeMax, 5)}`;
    } else {
        dailyVolume = '';
    }

    return (
        <div className={style.orders}>
            <div className={style.orders__titleWrapper}>
                <p className={style.orders__title}>{t('generals.createNew')}</p>
                <Checkbox
                    className="trackSellToBuyOrders"
                    checked={fakeVolumeControl}
                    onChange={fakeVolumeControlToggle}
                >
                    {t('generals.fakeVolumeControl')}
                </Checkbox>
            </div>
            <div className={style.orders__row}>
                <div className={style.orders__wrapperInput}>
                    <p className={style.orders__wrapper_title}>{t('generals.pair')}</p>
                    <Select
                        showSearch
                        value={pairName || undefined}
                        className="antDSelect"
                        placeholder={t('generals.choose')}
                        onChange={selectOnChange}
                    >
                        {availableNewPairs.map(items => (
                            <Option key={items} name="pairName" value={items}>
                                {items}
                            </Option>
                        ))}
                    </Select>
                    {pairNameError ? <div className={style.orders__wrapperInput_invalid}>{pairNameError}</div> : null}
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="userId"
                        type="number"
                        placeholder={t('generals.typeHere')}
                        name="userId"
                        value={userId}
                        error={userIdError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.userId')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="buySellCoef"
                        type="text"
                        placeholder={t('generals.typeHere')}
                        name="buySellCoef"
                        value={buySellCoef}
                        error={buySellCoefError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.buySellCoef')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="volumeLimit"
                        type="number"
                        placeholder={t('generals.typeHere')}
                        name="volumeLimit"
                        value={volumeLimit}
                        error={volumeLimitError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.tradeVolumeLimit')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
            </div>
            <div className={style.orders__row}>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="buyValueMin"
                        type="number"
                        placeholder={t('generals.min')}
                        name="buyValueMin"
                        value={buyValueMin}
                        error={buyValueMinError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.valueMin')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="buyValueMax"
                        type="number"
                        placeholder={t('generals.max')}
                        name="buyValueMax"
                        value={buyValueMax}
                        error={buyValueMaxError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.valueMax')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="minOrderVolume"
                        type="number"
                        placeholder={t('generals.min')}
                        name="minOrderVolume"
                        value={minOrderVolume}
                        error={minOrderVolumeError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.minOrderVolume')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="maxOrderVolume"
                        type="number"
                        placeholder={t('generals.max')}
                        name="maxOrderVolume"
                        value={maxOrderVolume}
                        error={maxOrderVolumeError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.maxOrderVolume')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
            </div>
            <div className={style.orders__row}>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="ordersQuantityMin"
                        type="number"
                        placeholder={t('generals.amount')}
                        name="ordersQuantityMin"
                        value={ordersQuantityMin}
                        error={ordersQuantityMinError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.ordersPerDealsMin')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="ordersQuantityMax"
                        type="number"
                        placeholder={t('generals.amount')}
                        name="ordersQuantityMax"
                        value={ordersQuantityMax}
                        error={ordersQuantityMaxError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.ordersPerDealsMax')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="frequencyMin"
                        type="number"
                        placeholder={t('generals.min')}
                        name="frequencyMin"
                        value={frequencyMin}
                        error={frequencyMinError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.frequencyMin')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <div className={style.orders__wrapperInput}>
                    <Field
                        id="frequencyMax"
                        type="number"
                        placeholder={t('generals.max')}
                        name="frequencyMax"
                        value={frequencyMax}
                        error={frequencyMaxError}
                        onChange={inputOnchange}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.frequencyMax')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
            </div>
            <div className={style.orders__row}>
                <div className={classNames(style.orders__wrapperInput, style.orders__wrapperInput_disabled_left)}>
                    <Field
                        id="dailyVolume"
                        type="text"
                        placeholder={t('generals.dailyVolume')}
                        name="dailyVolume"
                        value={dailyVolume}
                        inputStyle={style.orders__wrapper_input}
                        labelText={t('generals.dailyVolume')}
                        labelStyle={style.orders__wrapper_label}
                    />
                </div>
                <Button id="confirm" type="button" className={style.orders__confirmBtn} onClick={confirm}>
                    {t('generals.confirm')}
                </Button>
            </div>
        </div>
    );
};

CreateNewOrderView.defaultProps = {
    t: () => {},
    inputOnchange: () => {},
    selectOnChange: () => {},
    confirm: () => {},
    fakeVolumeControlToggle: () => {},
    fakeVolumeControl: false,
    availableNewPairs: [],
    pairName: '',
    pairNameError: '',
    userId: '',
    userIdError: '',
    buySellCoef: '',
    buySellCoefError: '',
    buyValueMin: '',
    buyValueMinError: '',
    buyValueMax: '',
    buyValueMaxError: '',
    ordersQuantityMin: '',
    ordersQuantityMinError: '',
    ordersQuantityMax: '',
    ordersQuantityMaxError: '',
    frequencyMin: '',
    frequencyMinError: '',
    frequencyMax: '',
    volumeLimit: '',
    frequencyMaxError: '',
    volumeLimitError: '',
    minOrderVolume: '',
    maxOrderVolume: '',
    minOrderVolumeError: '',
    maxOrderVolumeError: '',
};

CreateNewOrderView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    selectOnChange: PropTypes.func,
    confirm: PropTypes.func,
    fakeVolumeControlToggle: PropTypes.func,
    fakeVolumeControl: PropTypes.bool,
    availableNewPairs: PropTypes.any,
    pairName: PropTypes.string,
    pairNameError: PropTypes.string,
    userId: PropTypes.string,
    userIdError: PropTypes.string,
    buySellCoef: PropTypes.string,
    buySellCoefError: PropTypes.string,
    buyValueMin: PropTypes.string,
    buyValueMinError: PropTypes.string,
    buyValueMax: PropTypes.string,
    buyValueMaxError: PropTypes.string,
    ordersQuantityMin: PropTypes.string,
    ordersQuantityMinError: PropTypes.string,
    ordersQuantityMax: PropTypes.string,
    ordersQuantityMaxError: PropTypes.string,
    frequencyMin: PropTypes.string,
    frequencyMinError: PropTypes.string,
    frequencyMax: PropTypes.string,
    frequencyMaxError: PropTypes.string,
    volumeLimit: PropTypes.string,
    volumeLimitError: PropTypes.string,
    minOrderVolume: PropTypes.string,
    maxOrderVolume: PropTypes.string,
    minOrderVolumeError: PropTypes.string,
    maxOrderVolumeError: PropTypes.string,
};

export default withTranslation()(CreateNewOrderView);
