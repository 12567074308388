import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'antd';

import Field from '../field';
import style from './daily-volume.module.scss';
import timeInputs from '../../../helpers/daily-volume';

import Button from '../button';

const DailyVolume = ({
    times, errors, onChange, refreshDailyPrediction, totalPercentsOfDailyVolume, disabled = false,
}) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className={style.dailyVolume__titleWrapper}>
                <p className={style.dailyVolume__title}>{t('createBot.percentsDailyVolume')}</p>
                <Button
                    id="refreshDailyPrediction"
                    type="button"
                    disabled={disabled}
                    className={style.dailyVolume__btnrefreshDailyPrediction}
                    onClick={refreshDailyPrediction}
                >
                    {t('createBot.refreshDailyPrediction')}
                    <Icon className={style.dailyVolume__btnrefreshDailyPrediction_spin} type="sync" />
                </Button>
                {totalPercentsOfDailyVolume ? (
                    <p className={style.dailyVolume__nowDailyVolumeIs}>
                        {t('createBot.nowDailyVolumeIs')}{' '}
                        <span className={style.dailyVolume__nowDailyVolumeIs_red}>{totalPercentsOfDailyVolume}</span> %
                    </p>
                ) : null}
            </div>
            {timeInputs.map(item => {
                const { value, label, error } = item;

                return (
                    <div key={value} className={style.inputWrapper}>
                        <Field
                            disabled={disabled}
                            id={value}
                            type="number"
                            placeholder={t('generals.typeHere')}
                            name={value}
                            value={times[value]}
                            error={errors[error]}
                            onChange={onChange}
                            inputStyle={style.inputWrapper_input}
                            labelText={label}
                            labelStyle={style.inputWrapper_label}
                        />
                    </div>
                );
            })}
        </Fragment>
    );
};

DailyVolume.defaultProps = {
    onChange: () => {},
    refreshDailyPrediction: () => {},
    times: {},
    errors: {},
    totalPercentsOfDailyVolume: 0,
    disabled: false,
};

DailyVolume.propTypes = {
    onChange: PropTypes.func,
    refreshDailyPrediction: PropTypes.func,
    times: PropTypes.object,
    errors: PropTypes.object,
    totalPercentsOfDailyVolume: PropTypes.number,
    disabled: PropTypes.bool,
};

export default DailyVolume;
