/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import withGetService from '../../../../../hoc/with-get-service';
import withPostService from '../../../../../hoc/with-post-service';
import { compose } from '../../../../../../utils';
import EditProviderBotView from './view-depth-bot-view';
import Spinner from '../../../../../spinner';
import ErrorIndicator from '../../../../error-page/error-indicator';
import { personalAreaPath, depthBotsPath } from '../../../../../../constants';
import fetchAllCoinsAction from '../../../../../../actions/getAllCoins.actions';
import fetchCurrenciesFiatAction from '../../../../../../actions/getCurrenciesAll.actions';
import fetchCommonPairsAction from '../../../../../../actions/getCommonPairs.actions';
import fetchTargetPairsAction from '../../../../../../actions/getTargetPairs.actions';
import fetchAllSourcesAction from '../../../../../../actions/getAllSources.actions';
import fetchDepthBotsAction from '../../../../../../actions/getDepth.actions';
import editDepthBotAction from '../../../../../../actions/editDepthBot.actions';

export class ViewDepthBotContainer extends Component {
    state = {
        createNewBot: {
            depth: '',
            minOrderAmount: '',
            maxOrderAmount: '',
            minPercentBetweenOrdersPrice: '',
            maxPercentBetweenOrdersPrice: '',
            spreadPriceDistancePercent: '',
            refreshSeconds: '',
            id: '',
            pair: {
                left: '',
                right: '',
            },
            substitutePair: {
                left: '',
                right: '',
            },
            priceSource: 'BINANCE',
            status: 'STOPPED',
            userId: null,
            tradingOnTargetExchangeEnabled: false,
            sourceVolumeEnabled: false,
            sourceVolumePercent: '',
            minTimeVolumeTradeSeconds: '',
            maxTimeVolumeTradeSeconds: '',
            minAmountPerTrade: '',
            dailyVolume: '',
            maxDailyVolumeDeviationPercent: '',
            //
            substitutePairName: '',
            substitutePairAutoPriceEnabled: false,
            substitutePairPriceCoefficient: '',
            directPairCourse: false,
            directPairName: '',
            substitutePairAutoPriceCurrencySource: '',
            substitutePairAutoPriceCurrencyTarget: '',
        },
        errors: {},
        loading: false,
    };

    componentDidMount() {
        const {
            fetchAllCoins,
            fetchAllSources,
            fetchCommonPairs,
            fetchTargetPairs,
            fetchDepthBots,
            fetchCurrenciesFiat,
        } = this.props;
        fetchDepthBots();
        fetchAllCoins();
        fetchAllSources();
        fetchCommonPairs();
        fetchTargetPairs();
        fetchCurrenciesFiat();
    }

    componentDidUpdate(prevProps, prevState) {
        const { createNewBot } = this.state;
        const {
            depthBots,
            match: {
                params: { id },
            },
            fetchCommonPairs,
            fetchAllCoins,
        } = this.props;

        if (depthBots !== prevProps.depthBots) {
            const filterBotsData = depthBots.filter(item => item.id === id);
            const filterBotsDataObject = Object.assign({}, filterBotsData[0]);
            const {
                substitutePair,
                directPair,
            } = filterBotsDataObject;

            this.setState({
                createNewBot: {
                    ...filterBotsDataObject,
                    substitutePairName: substitutePair ? `${substitutePair.left}-${substitutePair.right}` : '',
                    directPairName: directPair ? `${directPair.left}-${directPair.right}` : '',
                },
                loading: false,
            });
        }

        if (prevState.createNewBot.priceSource !== createNewBot.priceSource) {
            fetchCommonPairs(createNewBot.priceSource);
            fetchAllCoins(createNewBot.priceSource);
        }
    }

    // save input changes to state
    inputOnchange = event => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                [event.target.name]: event.target.value,
            },
        });
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        const { createNewBot } = this.state;

        if (name.props.name === 'id') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    pair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else if (name.props.name === 'pairName') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    pair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else if (name.props.name === 'substitutePairName') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    substitutePair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else if (name.props.name === 'directPairName') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    directPair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                },
            });
        }
    };

    // redirect to bots page
    cancelCreateBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${depthBotsPath}`);
    };

    // btn create bot
    submitcreateNewBot = event => {
        event.preventDefault();
        const {
            t,
            history,
            match: {
                params: { id: matchId },
            },
        } = this.props;
        const { createNewBot } = this.state;

        const errors = {};
        const {
            minOrderAmount,
            maxOrderAmount,
            depth,
            id,
            minPercentBetweenOrdersPrice,
            maxPercentBetweenOrdersPrice,
            spreadPriceDistancePercent,
            priceSource,
            refreshSeconds,
            userId,
            sourceVolumePercent,
            sourceVolumeEnabled,
            minTimeVolumeTradeSeconds,
            maxTimeVolumeTradeSeconds,
            minAmountPerTrade,
            dailyVolume,
            maxDailyVolumeDeviationPercent,
            //
            substitutePairName,
            substitutePairAutoPriceEnabled,

            pairSubstituteEnabled,
            substitutePairPriceCoefficient,
            directPairCourse,
            directPairName,
            substitutePairAutoPriceCurrencySource,
            substitutePairAutoPriceCurrencyTarget,
        } = createNewBot;

        this.setState({
            createNewBot: {
                ...createNewBot,
            },
        });

        if (userId?.length > 0) {
            if (!Number.isInteger(+userId)) {
                errors.userId = `${t('createBot.valueMustBeInteger')}`;
            }
            if (+userId < 0) {
                errors.userId = `${t('createBot.valueMustBePositive')}`;
            }

            if (userId.length < 1) {
                errors.userIdError = t('error.input', { count: 1 });
            }
        }

        if (!Number.isInteger(+depth)) {
            errors.depth = `${t('createBot.valueMustBeInteger')}`;
        }

        if (!Number.isInteger(+refreshSeconds)) {
            errors.refreshSeconds = `${t('createBot.valueMustBeInteger')}`;
        }
        if (+refreshSeconds < 1) {
            errors.refreshSeconds = `${t('generals.minValue')} 1`;
        }
        if (+depth < 1) {
            errors.depth = `${t('generals.minValue')} 1`;
        }

        if (+spreadPriceDistancePercent > 100) {
            errors.spreadPriceDistancePercent = `${t('createBot.maxValue')} 100`;
        }

        if (+spreadPriceDistancePercent <= 0) {
            errors.spreadPriceDistancePercent = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+maxPercentBetweenOrdersPrice <= 0) {
            errors.maxPercentBetweenOrdersPrice = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minPercentBetweenOrdersPrice <= 0) {
            errors.minPercentBetweenOrdersPrice = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+maxPercentBetweenOrdersPrice > 100) {
            errors.maxPercentBetweenOrdersPrice = `${t('createBot.maxValue')} 100`;
        }

        if (+minPercentBetweenOrdersPrice > 100) {
            errors.minPercentBetweenOrdersPrice = `${t('createBot.maxValue')} 100`;
        }

        if (+maxOrderAmount <= 0) {
            errors.maxOrderAmount = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minOrderAmount <= 0) {
            errors.minOrderAmount = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minOrderAmount >= +maxOrderAmount) {
            errors.minOrderAmount = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (+minPercentBetweenOrdersPrice >= +maxPercentBetweenOrdersPrice) {
            errors.minPercentBetweenOrdersPrice = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (id?.length < 1) {
            errors.pairNameError = t('general.selectFromTheList');
        }

        if (priceSource?.length < 1) {
            errors.sourceError = t('general.selectFromTheList');
        }

        if (minTimeVolumeTradeSeconds <= 0) {
            errors.minTimeVolumeTradeSeconds = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (maxTimeVolumeTradeSeconds <= 0) {
            errors.maxTimeVolumeTradeSeconds = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (+minTimeVolumeTradeSeconds >= +maxTimeVolumeTradeSeconds) {
            errors.minTimeVolumeTradeSeconds = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (minAmountPerTrade <= 0) {
            errors.minAmountPerTrade = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (dailyVolume <= 0) {
            errors.dailyVolume = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        if (maxDailyVolumeDeviationPercent > 100) {
            errors.maxDailyVolumeDeviationPercent = `${t('createBot.maxValue')} 100`;
        }

        if (maxDailyVolumeDeviationPercent <= 0) {
            errors.maxDailyVolumeDeviationPercent = `${t('createBot.valueMustGreaterThanZero')}`;
        }

        //

        if (pairSubstituteEnabled && substitutePairName.length < 1) {
            errors.substitutePairNameError = t('general.selectFromTheList');
        }

        if (pairSubstituteEnabled && !substitutePairAutoPriceEnabled && substitutePairPriceCoefficient <= 0) {
            errors.substitutePairPriceCoefficientError = t('createBot.valueMustGreaterThanZero');
        }

        if (directPairCourse && directPairName.length < 1) {
            errors.directPairError = t('general.selectFromTheList');
        }

        if (sourceVolumeEnabled && sourceVolumePercent > 100) {
            errors.sourceVolumePercent = `${t('createBot.maxValue')} 100`;
        }

        if (sourceVolumeEnabled && sourceVolumePercent <= 0) {
            errors.sourceVolumePercent = `${t('createBot.valueMustGreaterThanZero')}`;
        }
        if (
            !directPairCourse
            && pairSubstituteEnabled
            && substitutePairAutoPriceEnabled
            && !substitutePairAutoPriceCurrencySource
        ) {
            errors.substitutePairAutoPriceCurrencySourceError = t('general.selectFromTheList');
        }

        if (
            !directPairCourse
            && pairSubstituteEnabled
            && substitutePairAutoPriceEnabled
            && !substitutePairAutoPriceCurrencyTarget
        ) {
            errors.substitutePairAutoPriceCurrencyTargetError = t('general.selectFromTheList');
        }

        if (
            !directPairCourse
            && pairSubstituteEnabled
            && substitutePairAutoPriceEnabled
            && substitutePairAutoPriceCurrencySource === substitutePairAutoPriceCurrencyTarget
        ) {
            errors.substitutePairAutoPriceCurrencySourceError = t('newOrder.differentCurrencies');
            errors.substitutePairAutoPriceCurrencyTargetError = t('newOrder.differentCurrencies');
        }


        if (Object.keys(errors).length > 0) {
            message.error(t('general.errorFields'), 2);
            return this.setState({
                errors,
            });
        }

        this.setState(
            {
                createNewBot: {
                    ...this.state.createNewBot,
                },
            },
            () => {
                if (Object.keys(errors).length > 0) {
                    this.setState({
                        errors,
                    });
                    message.error(t('general.errorFields'), 2);
                } else {
                    const { fetchEditDepthBot } = this.props;
                    fetchEditDepthBot(
                        {
                            ...createNewBot,
                            userId: createNewBot.userId?.length > 0 ? createNewBot.userId : null,
                        },
                        matchId,
                        t,
                        history,
                    );
                }
            },
        );
    };

    switchTradingOnTargetExchangeEnabled = e => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                tradingOnTargetExchangeEnabled: !!e.target.checked,
            },
        });
    };

    pairSubstitutionToggle = e => {
        const { createNewBot } = this.state;

        if (e.target.checked === false) {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    pairSubstituteEnabled: false,
                    substitutePairPriceCoefficient: '',
                    substitutePair: null,
                    substitutePairName: '',
                    directPair: null,
                    directPairName: '',
                    pairName: '',
                    directPairCourse: false,
                    substitutePairAutoPriceEnabled: false,
                    substitutePairAutoPriceCurrencySource: '',
                    substitutePairAutoPriceCurrencyTarget: '',
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    pairSubstituteEnabled: true,
                    pairName: '',
                    substitutePairName: '',
                    directPair: null,
                    directPairName: '',
                },
            });
        }
    };

    switchSourceVolumeEnabled = e => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                sourceVolumeEnabled: !!e.target.checked,
            },
        });
    };


    switchCustomPrice = e => {
        const { createNewBot } = this.state;
        if (e.target.checked === false) {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    substitutePairPriceCoefficient: '',
                    substitutePairAutoPriceCurrencySource: '',
                    substitutePairAutoPriceCurrencyTarget: '',
                    substitutePairAutoPriceEnabled: false,
                    directPair: null,
                    directPairName: '',
                    directPairCourse: false,
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    substitutePairAutoPriceEnabled: true,
                    directPairCourse: false,
                },
            });
        }
    };

    switchDirectBinanceRate = e => {
        const { createNewBot } = this.state;
        if (e.target.checked === false) {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    directPairCourse: false,
                    directPair: null,
                    directPairName: '',
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    directPairCourse: true,
                    substitutePairAutoPriceCurrencySource: '',
                    substitutePairAutoPriceCurrencyTarget: '',
                },
            });
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const {
            createNewBot,
            loading,
            error,
            errors,
        } = this.state;
        const {
            editDepthBotLoading,
            allSources,
            commonPairs,
            targetPairs,
            allCoins,
            currenciesAll,
        } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <EditProviderBotView
                createNewBot={createNewBot}
                errors={errors}
                allSources={allSources?.length ? allSources : ['BINANCE']}
                commonPairs={commonPairs}
                targetPairs={targetPairs}
                editDepthBotLoading={editDepthBotLoading}
                inputOnchange={this.inputOnchange}
                selectOnChange={this.selectOnChange}
                cancelCreateBot={this.cancelCreateBot}
                submitcreateNewBot={this.submitcreateNewBot}
                switchTradingOnTargetExchangeEnabled={this.switchTradingOnTargetExchangeEnabled}
                switchSourceVolumeEnabled={this.switchSourceVolumeEnabled}
                //
                pairSubstitutionToggle={this.pairSubstitutionToggle}
                allCoins={allCoins}
                currenciesAll={currenciesAll}
                switchCustomPrice={this.switchCustomPrice}
                switchDirectBinanceRate={this.switchDirectBinanceRate}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

ViewDepthBotContainer.defaultProps = {
    t: () => {
    },
    history: {},
    match: {},
    fetchEditDepthBot: () => {
    },
    fetchDepthBots: () => {
    },
    fetchAllCoins: () => {
    },
    fetchAllSources: () => {
    },
    fetchCommonPairs: () => {
    },
    fetchTargetPairs: () => {
    },
    fetchCurrenciesFiat: () => {
    },
    editDepthBotLoading: false,
    allSources: [],
    commonPairs: [],
    targetPairs: [],
    depthBots: [],
    allCoins: [],
    currenciesAll: [],
};

ViewDepthBotContainer.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
    fetchEditDepthBot: PropTypes.func,
    fetchAllCoins: PropTypes.func,
    fetchAllSources: PropTypes.func,
    fetchDepthBots: PropTypes.func,
    fetchCommonPairs: PropTypes.func,
    fetchTargetPairs: PropTypes.func,
    fetchCurrenciesFiat: PropTypes.func,
    editDepthBotLoading: PropTypes.bool,
    allSources: PropTypes.arrayOf(PropTypes.string),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    depthBots: PropTypes.arrayOf(PropTypes.object),
    allCoins: PropTypes.arrayOf(PropTypes.object),
    currenciesAll: PropTypes.any,
};

const mapStateToProps = state => {
    const {
        getDepthBot: { depthBots },
        editDepthBot: { loading: editDepthBotLoading },
        allCoins: { allCoins },
        allSources: { allSources },
        commonPairs: { commonPairs },
        targetPairs: { targetPairs },
        currenciesAll: { data: currenciesAll },
    } = state;
    return {
        depthBots,
        editDepthBotLoading,
        allCoins,
        allSources,
        commonPairs,
        targetPairs,
        currenciesAll,
    };
};

const mapDispatchToProps = (dispatch, {
    getService,
    postService,
}) => bindActionCreators(
    {
        fetchAllCoins: fetchAllCoinsAction(getService),
        fetchCommonPairs: fetchCommonPairsAction(getService),
        fetchTargetPairs: fetchTargetPairsAction(getService),
        fetchCurrenciesFiat: fetchCurrenciesFiatAction(getService),
        fetchEditDepthBot: editDepthBotAction(postService),
        fetchAllSources: fetchAllSourcesAction(getService),
        fetchDepthBots: fetchDepthBotsAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(ViewDepthBotContainer);
