import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BackTop } from 'antd';
import {
    personalAreaPath,
    providerBotsPath,
    providerBotsCreatePath,
    strategyBotsCreatePath,
    providerBotsEditPath,
    providerBotsStatistics,
    depthBotsPath,
    depthBotsCreatePath,
    depthBotsEditPath,
    ordersPath,
    createNewOrderPath,
    editOrderPath,
    strategyBotsPath,
    strategyBotsEditPath,
} from '../../../../constants';

import Aside from './aside';
import ProviderBotContainer from './provider-bot-page';
import ViewProviderBotContainer from './ViewPage/provider';

import StrategyBotContainer from './strategy-bot-page';
import ViewStrategyBotContainer from './ViewPage/strategy';

import CreateProviderBotContainer from './create-provider-bot-page';
import CreateStrategyBotContainer from './create-strategy-bot-page';
import EditProviderBotContainer from './edit-provider-bot-page';
import EditStrategyBotContainer from './edit-strategy-bot-page';
import ProviderStatisticsContainer from './provider-bot-statistics';

import OrdersContainer from './orders-page';
import ViewOrderContainer from './ViewPage/orders';

import CreateNewOrderContainer from './create-new-order-page';
import EditOrderContainer from './edit-order-page';

import DepthBotContainer from './depth-bot-page/depth-bot-container';
import ViewDepthBotContainer from './ViewPage/depth';
import CreateDepthBotContainer from './create-depth-bot-page/create-depth-bot-container';
import EditDepthBotContainer from './edit-depth-bot-page/edit-depth-bot-container';

import style from './personal-area.module.scss';
import './personal-area.scss';
import top from '../../../assets/images/top.svg';

const PersonalAreaView = () => (
    <div className={style.personalAreaWrapper}>
        <Aside />
        <Switch>
            {/* provider */}
            <Route path={`${personalAreaPath}${providerBotsPath}`} component={ProviderBotContainer} exact />
            <Route path={`${personalAreaPath}${providerBotsPath}/:id?`} component={ViewProviderBotContainer} />
            <Route path={`${personalAreaPath}${providerBotsCreatePath}`} component={CreateProviderBotContainer} />
            <Route path={`${personalAreaPath}${providerBotsEditPath}/:id?`} component={EditProviderBotContainer} />
            <Route path={`${personalAreaPath}${providerBotsStatistics}/:id?`} component={ProviderStatisticsContainer} />
            {/* strategy */}
            <Route path={`${personalAreaPath}${strategyBotsPath}`} component={StrategyBotContainer} exact />
            <Route path={`${personalAreaPath}${strategyBotsPath}/:id?`} component={ViewStrategyBotContainer} />
            <Route path={`${personalAreaPath}${strategyBotsCreatePath}`} component={CreateStrategyBotContainer} />
            <Route path={`${personalAreaPath}${strategyBotsEditPath}/:id?`} component={EditStrategyBotContainer} />
            {/* Depth  */}
            <Route path={`${personalAreaPath}${depthBotsPath}`} component={DepthBotContainer} exact />
            <Route path={`${personalAreaPath}${depthBotsPath}/:id?`} component={ViewDepthBotContainer} />
            <Route path={`${personalAreaPath}${depthBotsCreatePath}`} component={CreateDepthBotContainer} />
            <Route path={`${personalAreaPath}${depthBotsEditPath}/:id?`} component={EditDepthBotContainer} />
            {/* Orders */}
            <Route path={`${personalAreaPath}${ordersPath}`} component={OrdersContainer} exact />
            <Route path={`${personalAreaPath}${ordersPath}/:id?`} component={ViewOrderContainer} />
            <Route path={`${personalAreaPath}${createNewOrderPath}`} component={CreateNewOrderContainer} />
            <Route path={`${personalAreaPath}${editOrderPath}/:id?`} component={EditOrderContainer} />
        </Switch>
        <BackTop>
            <div className="ant-back-top-inner">
                <img src={top} alt="top" />
            </div>
        </BackTop>
    </div>
);

export default PersonalAreaView;
