import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import Button from '../button';
import {
    depthBotsPath, STATUS_BOT_RUNNING, STATUS_ENABLED, strategyBotsPath,
} from '../../../constants';
import style from './bot-table-row.module.scss';

const BotTableRow = props => {
    const {
        botsData,
        switchStatusProvider,
        cancelOrders,
        editProviderBot,
        cannotChahgeBot,
        isShowDailyVolume,
        isCancelOrders,
        deleteBotHandler,
        goToPreview,
    } = props;
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const isStrategyBots = pathname.includes(strategyBotsPath);
    const isDepthBots = pathname.includes(depthBotsPath);

    return (
        <Fragment>
            {botsData.length < 1 ? (
                <p className={style.botTable__noBots}>{t('createBot.noBots')}</p>
            ) : (
                <Fragment>
                    {botsData.map((item, index) => {
                        const {
                            id,
                            volumePerDay,
                            status,
                            minTradeAmount,
                            maxTradeAmount,
                            frequencySecondsMax,
                            frequencySecondsMin,
                        } = item;
                        const isEnabledStatus = [STATUS_BOT_RUNNING, STATUS_ENABLED];
                        const dailyVolumeMin = (minTradeAmount * 86400) / frequencySecondsMax;
                        const dailyVolumeMax = (maxTradeAmount * 86400) / frequencySecondsMin;
                        const dailyVolume = `${dailyVolumeMin} - ${dailyVolumeMax}`;
                        const statusStyle = isEnabledStatus.includes(status)
                            ? style.botTable__status_green
                            : style.botTable__status_red;
                        const currenVolume = isStrategyBots ? dailyVolume : volumePerDay;
                        const isShowSpreadPercent = isDepthBots ? item.spreadPriceDistancePercent : null;
                        return (
                            <Fragment key={id}>
                                <div className={style.botTable}>
                                    <div className={style.botTable__id}>
                                        <p className={style.botTable__label}>Id</p>
                                        <p className={style.botTable__id_value}>{index + 1}</p>
                                    </div>
                                    <div className={style.botTable__pair}>
                                        <p className={style.botTable__label}>{t('generals.pair')}</p>
                                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                                        <p
                                            className={style.botTable__pair_value}
                                            onClick={() => goToPreview(id)}
                                        >
                                            {id}
                                            <img
                                                onClick={() => goToPreview(id)}
                                                className={style.previewIcon}
                                                src="/preview-svgrepo-com.svg"
                                                alt="preview"
                                            />
                                        </p>
                                    </div>
                                    {isShowSpreadPercent ? (
                                        <div className={style.botTable__dailyVolume}>
                                            <p className={style.botTable__label}>{t('generals.spreadPercent')}</p>
                                            <p className={style.botTable__dailyVolume_value}>{isShowSpreadPercent}</p>
                                        </div>
                                    ) : null}
                                    {isShowDailyVolume ? (
                                        <div className={style.botTable__dailyVolume}>
                                            <p className={style.botTable__label}>{t('generals.dailyVolume')}</p>
                                            <p className={style.botTable__dailyVolume_value}>
                                                <Tooltip placement="top" overlay={<span>{currenVolume}</span>}>
                                                    {currenVolume}
                                                </Tooltip>
                                            </p>
                                        </div>
                                    ) : null}
                                    <div className={style.botTable__status}>
                                        <p className={style.botTable__label}>{t('generals.status')}</p>
                                        <p className={statusStyle}>{!status ? 'DISABLED' : status}</p>
                                    </div>
                                    <div className={style.botTable__buttonsWrapper}>
                                        {isEnabledStatus.includes(status) ? (
                                            <Button
                                                type="button"
                                                className={style.botTable__stopBtn}
                                                onClick={() => switchStatusProvider(id)}
                                            >
                                                {t('generals.stop')}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="button"
                                                className={style.botTable__editBtn}
                                                onClick={() => switchStatusProvider(id)}
                                            >
                                                {t('generals.start')}
                                            </Button>
                                        )}
                                        <Button
                                            type="button"
                                            className={style.botTable__deleteBtn}
                                            onClick={
                                                isEnabledStatus.includes(status)
                                                    ? cannotChahgeBot
                                                    : () => deleteBotHandler(id)
                                            }
                                        >
                                            {t('generals.delete')}
                                        </Button>
                                        <Button
                                            type="button"
                                            className={style.botTable__editBtn}
                                            onClick={
                                                status === STATUS_BOT_RUNNING
                                                    ? cannotChahgeBot
                                                    : () => {
                                                        editProviderBot(id);
                                                    }
                                            }
                                        >
                                            {t('generals.edit')}
                                        </Button>
                                        {isCancelOrders === true ? (
                                            <Button
                                                type="button"
                                                className={style.botTable__deleteBtn}
                                                onClick={
                                                    isEnabledStatus.includes(status)
                                                        ? cannotChahgeBot
                                                        : () => cancelOrders(id)
                                                }
                                            >
                                                {t('generals.cancelOrders')}
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })}
                </Fragment>
            )}
        </Fragment>
    );
};

BotTableRow.defaultProps = {
    botsData: [],
    switchStatusProvider: () => {
    },
    cancelOrders: () => {
    },
    editProviderBot: () => {
    },
    cannotChahgeBot: () => {
    },
    deleteBotHandler: () => {
    },
    isShowDailyVolume: true,
    isCancelOrders: false,
    goToPreview: () => {
    },
};

BotTableRow.propTypes = {
    botsData: PropTypes.arrayOf(PropTypes.object),
    switchStatusProvider: PropTypes.func,
    cancelOrders: PropTypes.func,
    editProviderBot: PropTypes.func,
    cannotChahgeBot: PropTypes.func,
    deleteBotHandler: PropTypes.func,
    isShowDailyVolume: PropTypes.bool,
    isCancelOrders: PropTypes.bool,
    goToPreview: PropTypes.func,
};

export default BotTableRow;
