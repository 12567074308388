/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import withGetService from '../../../../hoc/with-get-service';
import withDeleteService from '../../../../hoc/with-delete-service';
import withPatchService from '../../../../hoc/with-patch-service';
import withPostService from '../../../../hoc/with-post-service';
import { compose } from '../../../../../utils';
import OrdersView from './orders-view';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import fetchOrdersAction from '../../../../../actions/getOrders.actions';
import updateOrderStatusAction from '../../../../../actions/patchUpdateOrderStatus.actions';
import updateAllStatusAction from '../../../../../actions/putUpdateAllStatus.actions';
import deletOrderAction from '../../../../../actions/deleteOrder.actions';
import {
    personalAreaPath,
    createNewOrderPath,
    editOrderPath,
    STATUS_ENABLED,
    STATUS_DISABLED, ordersPath,
} from '../../../../../constants';

const initialState = {
    pairName: '',
    yesBtnAreUSureModalItem: '',
    currentPairName: '',
    isShowPairs: false,
    areUSureModal: false,
    rowCount: [1],
    allOrdersStatus: '',
};
export class OrdersContainer extends Component {
    state = { ...initialState };

    componentDidMount() {
        const { getOrders } = this.props;
        getOrders();
    }

    componentDidUpdate(prevProps) {
        const {
            getOrders,
            successUpdateOrderStatus,
            successUpdateAllStatus,
            successDeleteOrder,
            successOrders,
        } = this.props;

        if (successUpdateOrderStatus && successUpdateOrderStatus !== prevProps.successUpdateOrderStatus) {
            getOrders();
        }

        if (successDeleteOrder && successDeleteOrder !== prevProps.successDeleteOrder) {
            getOrders();
        }

        if (successUpdateAllStatus && successUpdateAllStatus !== prevProps.successUpdateAllStatus) {
            getOrders();
        }

        if (successOrders && successOrders !== prevProps.successOrders) {
            this.checkAllStatus();
        }
    }

    checkAllStatus = () => {
        const { orders } = this.props;

        const isEnabledOrder = orders.find(item => item.status === STATUS_ENABLED);

        if (!isEnabledOrder) {
            this.setState({
                allOrdersStatus: STATUS_DISABLED,
            });
        } else {
            this.setState({
                allOrdersStatus: STATUS_ENABLED,
            });
        }
    };

    createNewOrder = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${createNewOrderPath}`);
    };

    stopAllOrders = () => {
        const { t, updateAllStatus } = this.props;
        updateAllStatus(STATUS_DISABLED, t);
    };

    startAllOrders = () => {
        const { t, updateAllStatus } = this.props;
        updateAllStatus(STATUS_ENABLED, t);
    };

    switchOrderStatus = pairName => {
        this.setState(
            {
                yesBtnAreUSureModalItem: 'switchStatusOrder',
                currentPairName: pairName,
            },
            () => {
                this.switchAreUSureModal();
            },
        );
    };

    deleteOrder = pairName => {
        this.setState(
            {
                yesBtnAreUSureModalItem: 'deleteOrder',
                currentPairName: pairName,
            },
            () => {
                this.switchAreUSureModal();
            },
        );
    };

    editOrder = pairName => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${editOrderPath}/${pairName}`);
    };

    cannotChahgeOrder = () => {
        const { t } = this.props;
        message.error(t('createBot.NeedTurnOffBot'), 2);
    };

    switchAreUSureModal = () => {
        const { areUSureModal } = this.state;
        this.setState({
            areUSureModal: !areUSureModal,
        });
    };

    yesBtnAreUSureModal = () => {
        const {
            updateOrderStatus, deletOrder, orders, t,
        } = this.props;
        const { yesBtnAreUSureModalItem, currentPairName } = this.state;
        this.setState({
            areUSureModal: false,
        });

        if (yesBtnAreUSureModalItem === 'switchStatusOrder') {
            const findOrder = orders.find(item => item.id === currentPairName);
            const { status } = findOrder;
            const checkStatus = !status || status === STATUS_DISABLED ? STATUS_ENABLED : STATUS_DISABLED;
            updateOrderStatus(currentPairName, checkStatus, t);
        }

        if (yesBtnAreUSureModalItem === 'deleteOrder') {
            deletOrder(currentPairName, t);
        }
    };

    onError = () => {
        this.setState({
            error: true,
        });
    };

    goToPreview = id => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${ordersPath}/${id}`);
    }

    render() {
        const { error, areUSureModal, allOrdersStatus } = this.state;
        const { orders, ordersLoading } = this.props;

        const hasData = !(ordersLoading || error);
        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = ordersLoading ? <Spinner /> : null;
        const content = hasData ? (
            <OrdersView
                orders={orders}
                areUSureModal={areUSureModal}
                allOrdersStatus={allOrdersStatus}
                createNewOrder={this.createNewOrder}
                stopAllOrders={this.stopAllOrders}
                startAllOrders={this.startAllOrders}
                switchOrderStatus={this.switchOrderStatus}
                deleteOrder={this.deleteOrder}
                editOrder={this.editOrder}
                cannotChahgeBot={this.cannotChahgeOrder}
                switchAreUSureModal={this.switchAreUSureModal}
                yesBtnAreUSureModal={this.yesBtnAreUSureModal}
                goToPreview={this.goToPreview}

            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

OrdersContainer.defaultProps = {
    t: () => {},
    getOrders: () => {},
    updateOrderStatus: () => {},
    deletOrder: () => {},
    updateAllStatus: () => {},
    orders: [],
    history: {},
    ordersLoading: true,
    successUpdateOrderStatus: false,
    successDeleteOrder: false,
    successOrders: false,
    successUpdateAllStatus: false,
};

OrdersContainer.propTypes = {
    t: PropTypes.func,
    getOrders: PropTypes.func,
    updateOrderStatus: PropTypes.func,
    deletOrder: PropTypes.func,
    updateAllStatus: PropTypes.func,
    history: PropTypes.object,
    orders: PropTypes.any,
    ordersLoading: PropTypes.bool,
    successUpdateOrderStatus: PropTypes.bool,
    successDeleteOrder: PropTypes.bool,
    successOrders: PropTypes.bool,
    successUpdateAllStatus: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        orders: { data: orders, success: successOrders, loading: ordersLoading },
        updateOrderStatus: { success: successUpdateOrderStatus },
        deleteOrder: { success: successDeleteOrder },
        updateAllStatus: { success: successUpdateAllStatus },
    } = state;

    return {
        successOrders,
        orders,
        ordersLoading,
        successUpdateOrderStatus,
        successDeleteOrder,
        successUpdateAllStatus,
    };
};

const mapDispatchToProps = (dispatch, {
    getService, deleteService, patchService, postService,
}) => bindActionCreators(
    {
        getOrders: fetchOrdersAction(getService),
        deletOrder: deletOrderAction(deleteService),
        updateOrderStatus: updateOrderStatusAction(patchService),
        updateAllStatus: updateAllStatusAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withDeleteService(),
    withPatchService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(OrdersContainer);
