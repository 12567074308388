/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import withGetService from '../../../../../hoc/with-get-service';
import withPostService from '../../../../../hoc/with-post-service';
import { compose } from '../../../../../../utils';
import ViewStrategyBotView from './view-strategy-bot-view';
import Spinner from '../../../../../spinner';
import ErrorIndicator from '../../../../error-page/error-indicator';
import editStrategyBotAction from '../../../../../../actions/editStrategyBot.actions';
import fetchStrategyBotsAction from '../../../../../../actions/getStrategy.actions';
import { personalAreaPath, strategyBotsPath } from '../../../../../../constants';
import fetchAllCoinsAction from '../../../../../../actions/getAllCoins.actions';
import fetchCurrenciesFiatAction from '../../../../../../actions/getCurrenciesAll.actions';
import fetchCommonPairsAction from '../../../../../../actions/getCommonPairs.actions';
import fetchTargetPairsAction from '../../../../../../actions/getTargetPairs.actions';
import fetchAllSourcesAction from '../../../../../../actions/getAllSources.actions';

export class ViewStrategyBotContainer extends Component {
    // TOTO clean this
    state = {
        createNewBot: {
            status: 'STOPPED',
            priceSource: 'BINANCE',
            pair: {
                left: '',
                right: '',
            },
            id: '',
            userId: null,
        },
        errors: {},
        loading: false,
    };

    componentDidMount() {
        const {
            fetchAllCoins,
            fetchAllSources,
            fetchCommonPairs,
            fetchTargetPairs,
            fetchStrategyBots,
            fetchCurrenciesFiat,
        } = this.props;
        fetchStrategyBots();
        fetchAllCoins();
        fetchAllSources();
        fetchCommonPairs();
        fetchTargetPairs();
        fetchCurrenciesFiat();
    }

    componentDidUpdate(prevProps, prevState) {
        const { createNewBot } = this.state;
        const {
            strategyBots,
            match: {
                params: { id },
            },
            fetchCommonPairs,
            fetchAllCoins,
        } = this.props;
        if (strategyBots !== prevProps.strategyBots) {
            const filterBotsData = strategyBots.filter(item => item.id === id);
            const filterBotsDataObject = Object.assign({}, filterBotsData[0]);
            this.setState({
                createNewBot: {
                    ...filterBotsDataObject,
                },
                loading: false,
            });
        }

        if (prevState.createNewBot.priceSource !== createNewBot.priceSource) {
            fetchCommonPairs(createNewBot.priceSource);
            fetchAllCoins(createNewBot.priceSource);
        }
    }

    // save input changes to state
    inputOnchange = event => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                [event.target.name]: event.target.value,
            },
        });
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        const { createNewBot } = this.state;

        if (name.props.name === 'id') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    pair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                },
            });
        }
    };

    // redirect to bots page
    cancelCreateBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${strategyBotsPath}`);
    };

    switchSourceVolume = e => {
        const { createNewBot } = this.state;

        this.setState({
            createNewBot: {
                ...createNewBot,
                priceFromSource: !!e.target.checked,
            },
        });
    };

    // btn create bot
    submitcreateNewBot = event => {
        event.preventDefault();
        const {
            t,
            history,
            match: {
                params: { id: matchId },
            },
        } = this.props;
        const { createNewBot } = this.state;

        const errors = {};
        const {
            frequencySecondsMax,
            frequencySecondsMin,
            id,
            maxPrice,
            maxTradeAmount,
            minPrice,
            minTradeAmount,
            priceSource,
            priceFromSource,
            userId,
        } = createNewBot;

        this.setState({
            createNewBot: {
                ...createNewBot,
            },
        });

        if (userId?.length > 0) {
            if (!Number.isInteger(+userId)) {
                errors.userId = `${t('createBot.valueMustBeInteger')}`;
            }
            if (+userId < 0) {
                errors.userId = `${t('createBot.valueMustBePositive')}`;
            }

            if (userId.length < 1) {
                errors.userIdError = t('error.input', { count: 1 });
            }
        }

        if (!Number.isInteger(+frequencySecondsMin)) {
            errors.frequencySecondsMin = `${t('createBot.valueMustBeInteger')}`;
        }

        if (!Number.isInteger(+frequencySecondsMax)) {
            errors.frequencySecondsMax = `${t('createBot.valueMustBeInteger')}`;
        }
        if (+frequencySecondsMin < 1) {
            errors.frequencySecondsMin = `${t('generals.minValue')} 0`;
        }
        if (+frequencySecondsMax < 1) {
            errors.frequencySecondsMax = `${t('generals.minValue')} 0`;
        }

        if (+minTradeAmount <= 0) {
            errors.minTradeAmount = `${t('generals.minValue')} 0`;
        }

        if (+maxTradeAmount <= 0) {
            errors.maxTradeAmount = `${t('generals.minValue')} 0.1`;
        }

        if (!priceFromSource && +minPrice <= 0) {
            errors.minPrice = `${t('generals.minValue')} 0.1`;
        }

        if (!priceFromSource && +maxPrice <= 0) {
            errors.maxPrice = `${t('generals.minValue')} 0.1`;
        }
        if (+frequencySecondsMin >= +frequencySecondsMax) {
            errors.frequencySecondsMin = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (!priceFromSource && +minPrice >= +maxPrice) {
            errors.minPrice = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (+minTradeAmount >= +maxTradeAmount) {
            errors.minTradeAmount = `${t('generals.minValueMustBeLessMax')}`;
        }

        if (id?.length < 1) {
            errors.pairNameError = t('general.selectFromTheList');
        }

        if (priceSource?.length < 1) {
            errors.sourceError = t('general.selectFromTheList');
        }

        this.setState(
            {
                createNewBot: {
                    ...this.state.createNewBot,
                },
            },
            () => {
                if (Object.keys(errors).length > 0) {
                    this.setState({
                        errors,
                    });
                    message.error(t('general.errorFields'), 2);
                } else {
                    const { fetchEditStrategyBot } = this.props;
                    fetchEditStrategyBot(
                        {
                            ...createNewBot,
                            userId: createNewBot.userId?.length > 0 ? createNewBot.userId : null,
                        },
                        matchId,
                        t,
                        history,
                    );
                }
            },
        );
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const {
            createNewBot, loading, error, errors,
        } = this.state;
        const {
            editStrategyBotLoading, allSources, commonPairs, targetPairs,
        } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <ViewStrategyBotView
                createNewBot={createNewBot}
                errors={errors}
                allSources={allSources?.length ? allSources : ['BINANCE']}
                commonPairs={commonPairs}
                targetPairs={targetPairs}
                editStrategyBotLoading={editStrategyBotLoading}
                inputOnchange={this.inputOnchange}
                selectOnChange={this.selectOnChange}
                cancelCreateBot={this.cancelCreateBot}
                submitcreateNewBot={this.submitcreateNewBot}
                switchSourceVolume={this.switchSourceVolume}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

ViewStrategyBotContainer.defaultProps = {
    t: () => {},
    history: {},
    match: {},
    fetchEditStrategyBot: () => {},
    fetchStrategyBots: () => {},
    fetchAllCoins: () => {},
    fetchAllSources: () => {},
    fetchCommonPairs: () => {},
    fetchTargetPairs: () => {},
    fetchCurrenciesFiat: () => {},
    editStrategyBotLoading: false,
    allSources: [],
    commonPairs: [],
    targetPairs: [],
    strategyBots: [],
};

ViewStrategyBotContainer.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
    fetchEditStrategyBot: PropTypes.func,
    fetchAllCoins: PropTypes.func,
    fetchAllSources: PropTypes.func,
    fetchStrategyBots: PropTypes.func,
    fetchCommonPairs: PropTypes.func,
    fetchTargetPairs: PropTypes.func,
    fetchCurrenciesFiat: PropTypes.func,
    editStrategyBotLoading: PropTypes.bool,
    allSources: PropTypes.arrayOf(PropTypes.string),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    strategyBots: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
    const {
        getStrategyBot: { strategyBots },
        editStrategyBot: { loading: editStrategyBotLoading },
        allCoins: { allCoins },
        allSources: { allSources },
        commonPairs: { commonPairs },
        targetPairs: { targetPairs },
        currenciesAll: { data: currenciesAll },
    } = state;

    return {
        strategyBots,
        editStrategyBotLoading,
        allCoins,
        allSources,
        commonPairs,
        targetPairs,
        currenciesAll,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        fetchAllCoins: fetchAllCoinsAction(getService),
        fetchCommonPairs: fetchCommonPairsAction(getService),
        fetchTargetPairs: fetchTargetPairsAction(getService),
        fetchCurrenciesFiat: fetchCurrenciesFiatAction(getService),
        fetchEditStrategyBot: editStrategyBotAction(postService),
        fetchAllSources: fetchAllSourcesAction(getService),
        fetchStrategyBots: fetchStrategyBotsAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(ViewStrategyBotContainer);
