import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import Button from '../button';
import style from './green-red-button-block.module.scss';

const GreenRedButtonBlock = props => {
    const {
        clickGreenBtn,
        clickRedBtn,
        greenBtnText,
        redBtnText,
        disabled,
        botsData,
        hideIfLessOne,
        greenRedButtonWrapper,
        isView,
    } = props;

    const history = useHistory();
    const location = history.location.pathname;
    console.log('location', location);
    const goBackButton = () => {
        const path = location.split('/');
        // remove last element
        history.push(path.slice(0, path.length - 1).join('/'));
    };
    return (
        !isView ? (
            <div className={greenRedButtonWrapper}>
                <Button
                    onClick={clickGreenBtn}
                    className={style.greenRedButtonBlockWrapper__createNew}
                    type="button"
                    disabled={disabled}
                >
                    {greenBtnText}
                </Button>
                {hideIfLessOne && botsData.length < 1 ? null : (
                    <Button onClick={clickRedBtn} className={style.greenRedButtonBlockWrapper__stopAll} type="button">
                        {redBtnText}
                    </Button>
                )}
            </div>
        ) : (
            <div className={greenRedButtonWrapper}>
                <Button
                    onClick={goBackButton}
                    className={style.greenRedButtonBlockWrapper__createNew}
                    type="button"
                >
                   Go Back
                </Button>
            </div>
        )
    );
};

GreenRedButtonBlock.defaultProps = {
    clickGreenBtn: () => {
    },
    clickRedBtn: () => {
    },
    greenBtnText: '',
    redBtnText: '',
    disabled: false,
    hideIfLessOne: false,
    botsData: [],
    greenRedButtonWrapper: {},
    isView: false,
};

GreenRedButtonBlock.propTypes = {
    clickGreenBtn: PropTypes.func,
    clickRedBtn: PropTypes.func,
    greenBtnText: PropTypes.string,
    redBtnText: PropTypes.string,
    disabled: PropTypes.bool,
    hideIfLessOne: PropTypes.bool,
    botsData: PropTypes.arrayOf(PropTypes.object),
    greenRedButtonWrapper: PropTypes.any,
    isView: PropTypes.bool,
};

export default GreenRedButtonBlock;
